/* SPARKLINES */
require('jqvmap');
require('jqvmap/dist/maps/jquery.vmap.world');
require('jqvmap/dist/maps/jquery.vmap.usa');
require('jqvmap/dist/maps/jquery.vmap.brazil.js');


(function() {
    "use strict";
    const sample_data = require('jqvmap/examples/js/jquery.vmap.sampledata');
    var init_jqvmap = function () {
        var element = $(".jvmap");                    
        element.each(function () {
            var $this = $(this);
            var options = getDataOptions($this);            
            $this.vectorMap({
                "map":"brazil_br",
                "backgroundColor":null,                              
                "color":"#4cd137",
                "hoverOpacity":0.7,
                "selectedColor":"#44bd32",
                "enableZoom":false,
                "showTooltip":true,
                "values":"sample_data",
                "scaleColors":["#E6F2F0","#149B7E"],
                "normalizeFunction":"polynomial",              
                "onRegionClick": function(element, code, region)
                {
                                       
                },
                
            });
        });
    };

    init_jqvmap();

    //if chart inside a tab
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        //init_jqvmap();
    });
}());


