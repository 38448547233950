require('.././libs/dataTables.bootstrap4');
(function() {
    "use strict";
    var element = $(".data-tables");
    element.each(function () {
        var $this = $(this);
        var options = getDataOptions($this);
        if(options){
            $this.DataTable(options);
        }else{
            $this.DataTable();
        }
    });

     //Campanhas
    /*var campanhas = $(".data-tables-campanhas");
    campanhas.each(function () {
        var $this = $(this);
            
    });
    $this.DataTable({
        dom: "<'row'<'col-md-12'<'col-xs-6'l><'col-xs-6'p>>r>"+
            "<'row'<'col-md-12't>>"+
            "<'row'<'col-md-12'<'col-xs-6'i><'col-xs-6'p>>>",
        "processing": true,
        "serverSide": true,
        "autoWidth": true,
        "orderable": false,               
        "language": {
            "lengthMenu": "_MENU_ resultados por página",
            "zeroRecords": "Nenhum Resultado Encontrado",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Nenhum Registro Encontrado",
            "infoFiltered": "(Filtrado a partir _MAX_ total registros)",
            "sSearch": " ",
            "sSearchPlaceholder": "Buscar",
            "oPaginate": {
                        "sNext": "Próximo",
                        "sPrevious": "Anterior",
                        "sFirst": "Primeiro",
                        "sLast": "Último"

            }
        },
        ajax: {
            url: "/campanhas/ajax",
            type: "POST",
            data: function (d) {
                d.nome = $('input[name=nomeCampanha]').val();                       
            },
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        },
        columns: [
            {data: 'id', name: 'id'},
            {data: 'nome', name: 'nome'},
            {data: 'descricao', name: 'descricao'},
            {data: 'inicio', name: 'inicio'},
            {data: 'fim', name: 'fim'},
            {data: 'situacao', name: 'situacao'},
            {data: 'action', name: 'action'}
        ]

    });*/
    var oTable = $('.data-tables-campanhas').DataTable({  
        dom: "<'row'<'col-md-12'<'col-xs-6'l><'col-xs-6'p>>r>"+
            "<'row'<'col-md-12't>>"+
            "<'row'<'col-md-12'<'col-xs-6'i><'col-xs-6'p>>>",      
        processing: true,
        serverSide: true,
        autoWidth: true,
        orderable: false,
        searchable: false,
        language: {
            "lengthMenu": "_MENU_ resultados por página",
            "zeroRecords": "Nenhum Resultado Encontrado",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Nenhum Registro Encontrado",
            "infoFiltered": "(Filtrado a partir _MAX_ total registros)",            
            "oPaginate": {
                        "sNext": "Próximo",
                        "sPrevious": "Anterior",
                        "sFirst": "Primeiro",
                        "sLast": "Último"

            }
        },
        ajax: {
            url: "/campanhas/ajax",
            type: "POST",
            data: function (d) {
                d.nome = $('input[name=nomeCampanha]').val();
                d.inicio = $('input[name=inicioCampanha]').val();
                d.fim = $('input[name=fimCampanha]').val();                             
            },
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        },
        columns: [
            {data: 'id', name: 'id'},
            {data: 'nome', name: 'nome'},            
            {data: 'inicio', name: 'inicio'},
            {data: 'fim', name: 'fim'},
            {data: 'situacao', name: 'situacao'},
            {data: 'action', name: 'action', orderable: false, searchable: false}
        ]
    });

    $('#search-form-campanha').on('submit',function (e) {            
        oTable.draw();
        e.preventDefault();
    });

    //Transações
    var transacoes = $('.data-tables-transacoes').DataTable({  
        dom: "<'row'<'col-md-12'<'col-xs-6'l><'col-xs-6'p>>r>"+
            "<'row'<'col-md-12't>>"+
            "<'row'<'col-md-12'<'col-xs-6'i><'col-xs-6'p>>>",      
        processing: true,
        serverSide: true,
        autoWidth: true,
        orderable: false,
        searchable: false,
        pageLength: 20,
        language: {
            "lengthMenu": "_MENU_ resultados por página",
            "zeroRecords": "Nenhum Resultado Encontrado",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Nenhum Registro Encontrado",
            "infoFiltered": "(Filtrado a partir _MAX_ total registros)",            
            "oPaginate": {
                        "sNext": "Próximo",
                        "sPrevious": "Anterior",
                        "sFirst": "Primeiro",
                        "sLast": "Último"

            },
            "processing": 'Carregando Aguarde...'
        },
        ajax: {
            url: "/transacoes/ajax",
            type: "POST",
            data: function (d) {
                d.nome = $('input[name=nomeCliente]').val();
                d.codigo = $('input[name=codigoPedido]').val();                
                d.cpf = $('input[name=cpfCliente]').val();
                d.email = $('input[name=emailCliente]').val(); 
                d.data = $('input[name=dataTransacao]').val();
                d.campanha = $('#selectCampanha option:selected').val();
                d.situacao = $('#selectSituacao option:selected').val();                            
            },
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function() {
                //$(".loader").fadeIn("slow"); 
            }
            
        },
        columns: [
            {data: 'num_transacao', name: 'codigo'},
            {data: 'cliente_nome', name: 'nome'},                        
            {data: 'cliente_fone', name: 'celular'},
            {data: 'cliente_endereco', name: 'endereco'},
            {data: 'id_transportadora', name: 'transportadora'},
            {data: 'cod_rastreio', name: 'rastreio'},            
            {data: 'situacao', name: 'situacao'},
            {data: 'numero_nf', name: 'nf'},
            {data: 'data', name: 'data'},
            {data: 'action', name: 'comandos'}
        ]
    });

    $('#search-form-transacoes').on('submit',function (e) {    
        transacoes.draw();
        e.preventDefault();
        //$(".loader").fadeOut("slow");
    });

    //Requisições
    var requisicoes = $('.data-tables-requisicoes').DataTable({  
        dom: "<'row'<'col-md-12'<'col-xs-6'l><'col-xs-6'p>>r>"+
            "<'row'<'col-md-12't>>"+
            "<'row'<'col-md-12'<'col-xs-6'i><'col-xs-6'p>>>",      
        processing: true,
        serverSide: true,
        autoWidth: true,
        orderable: false,
        searchable: false,
        language: {
            "lengthMenu": "_MENU_ resultados por página",
            "zeroRecords": "Nenhum Resultado Encontrado",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Nenhum Registro Encontrado",
            "infoFiltered": "(Filtrado a partir _MAX_ total registros)",            
            "oPaginate": {
                        "sNext": "Próximo",
                        "sPrevious": "Anterior",
                        "sFirst": "Primeiro",
                        "sLast": "Último"

            }
        },
        ajax: {
            url: "/requisicoes/ajax",
            type: "POST",
            data: function (d) {               
                d.protocolo = $('input[name=protocolo]').val();                                                
                d.data = $('input[name=data]').val();
                d.responsavel = $("#selectResponsavel option:selected").val();                          
            },
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        },
        columns: [
            {data: 'protocolo', name: 'protocolo'},
            {data: 'id_transacao', name: 'transacao'},            
            {data: 'id_situacao', name: 'situacao'},
            {data: 'id_tipo', name: 'tipo'},
            {data: 'sla', name: 'sla'},
            {data: 'id_responsavel', name: 'responsavel'},
            {data: 'data1', name: 'data_criacao'},
            {data: 'data2', name: 'data_encerrado'},            
            {data: 'action', name: 'action'}
        ]
    });

    $('#search-form-requisicoes').on('submit',function (e) {    
        requisicoes.draw();
        e.preventDefault();
    });

    //Webhook
    var webhook = $('.data-tables-webhook').DataTable({  
        dom: "<'row'<'col-md-12'<'col-xs-6'l><'col-xs-6'p>>r>"+
            "<'row'<'col-md-12't>>"+
            "<'row'<'col-md-12'<'col-xs-6'i><'col-xs-6'p>>>",      
        processing: true,
        serverSide: true,
        autoWidth: true,
        orderable: false,
        searchable: false,
        language: {
            "lengthMenu": "_MENU_ resultados por página",
            "zeroRecords": "Nenhum Resultado Encontrado",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Nenhum Registro Encontrado",
            "infoFiltered": "(Filtrado a partir _MAX_ total registros)",            
            "oPaginate": {
                        "sNext": "Próximo",
                        "sPrevious": "Anterior",
                        "sFirst": "Primeiro",
                        "sLast": "Último"

            }
        },
        ajax: {
            url: "/admin/webhook/ajax",
            type: "POST",
            data: function (d) {               
                d.protocolo = $('input[name=protocolo]').val();                                                
                d.data = $('input[name=data]').val();
                d.responsavel = $("#selectResponsavel option:selected").val();                          
            },
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        },
        columns: [
            {data: 'protocolo', name: 'protocolo'},
            {data: 'id_transacao', name: 'transacao'},            
            {data: 'id_situacao', name: 'situacao'},
            {data: 'id_tipo', name: 'tipo'},
            {data: 'sla', name: 'sla'},
            {data: 'id_responsavel', name: 'responsavel'},
            {data: 'data1', name: 'data_criacao'},
            {data: 'data2', name: 'data_encerrado'},            
            {data: 'action', name: 'action', orderable: false, searchable: false}
        ]
    });

    $('#search-form-webhook').on('submit',function (e) {    
        /*requisicoes.draw();
        e.preventDefault();*/
        alert('OK');
    });

}());
