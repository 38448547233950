const Swal = require('sweetalert2');
// Format Number With Commas
getNumberWithCommas = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

isElementInViewport = function isElementInViewport(el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
};


$.fn.hasData = function(key) {
    return (typeof $(this).data(key) != 'undefined');
};

getDataOptions = function (x , options) {
    if(!options){
        options = 'options';
    }
    if($.fn.hasData(x)){
        var attrData = $.extend({}, x.data());
        return attrData.options ? attrData.options : null ;
    }
    return null;
};

//Modulo de Produtos
$("#addProdutoKit").on("click",function(e) {

    var produto = $("#selectProduto option:selected").val();
    var nome = $("#selectProduto option:selected").attr('data-nome');
    var tipo = $("#selectProduto option:selected").attr("data-tipo");
    var sku = $("#selectProduto option:selected").attr("data-sku");
    var qtd = $("input[name=qtd]").val();

    if(produto === ""){
        Swal.fire(
            'Atenção!',
            'Um produto precisa ser selecionado!',
            'error'
        );
        return false;
    }
    if(qtd === ""){
        Swal.fire(
            'Atenção!',
            'Uma quantidade precisa ser inserida!',
            'error'
        );
        return false;
    }
    $.ajax({
        type: "POST",
        url: "/funcoes",
        data: {funcao: "addProdutoKit", id: produto, nome: nome, qtd:qtd, tipo: tipo, sku: sku},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {

            if(data.result == true){

                /*$.get("/produtos/loadKitProduto", {
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                },
                    function (returnedHtml) {
                    $("div#exibeCategoria").html(returnedHtml);
                });*/
                $('#exibe').load('/produtos/loadKitProduto');


            }
            if(data.result == false){
                //sweetAlert("Atenção!", data.msg, "error", "Fechar");
                Swal.fire(
                    'Atenção!',
                    data.msg,
                    'error'
                );
                //$('#exibeCategoria').load('/products/loadoutrascategoria');
            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });
});
$(document).on("click","#deleteProdutoKit", function(e) {

   var produto = $(this).attr('data-produto');
   var tipo = $(this).attr('data-tipo');

    $.ajax({
        type: "POST",
        url: "/funcoes",
        data: {funcao: "deleteProdutoKit", id: produto, tipo: tipo},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {
            if(data.result == true){
                $('#exibe').load('/produtos/loadKitProduto');
            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });
});

//Codigo do Produto
$("#addProdutoCodigo").on("click",function(e) {

    var codigo = $("input[name=codigo]").val();
    var plataforma = $("input[name=plataforma]").val();
    var sku = $("input[name=skuCodigo]").val();
    var tipo = $(this).attr('data-tipo');

    if(codigo === ""){
        Swal.fire(
            'Atenção!',
            'Um codigo precisa ser preenchido!',
            'error'
        );
        return false;
    }
    /*if(qtd === ""){
        Swal.fire(
            'Atenção!',
            'Uma quantidade precisa ser inserida!',
            'error'
        );
        return false;
    }*/
    $.ajax({
        type: "POST",
        url: "/funcoes",
        data: {funcao: "addProdutoCodigo", codigo: codigo, sku: sku, plataforma: plataforma, tipo: tipo},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {

            if(data.result == true){

                $('#exibeCodigo').load('/produtos/loadProdutoCodigo');
            }
            if(data.result == false){
                Swal.fire(
                    'Atenção!',
                    data.msg,
                    'error'
                );

            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });
});

$(document).on("click","#deleteProdutoCodigo", function(e) {

    var produto = $(this).attr('data-produto');

     $.ajax({
         type: "POST",
         url: "/funcoes",
         data: {funcao: "deleteProdutoCodigo", id: produto},
         dataType: "json",
         headers: {
             'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
         },
         success: function( data )
         {
             if(data.result == true){
                Swal.fire(
                    'Atenção!',
                    'Codigo Excluido com sucesso!',
                    'success'
                );
                 $('#exibeCodigo').load('/produtos/loadProdutoCodigo');
             }

         },
         error: function(data) { // executa quando não responde ou envia um erro
         // em caso de erro você pode dar um alert('msg erro');
             //alert('Desculpe, houve um erro interno.');
             //alert(data);
         }
     });
 });


$('#addProduto').submit(function(event){
    //event.preventDefault();
    var formData = new FormData(this);
    $.ajax({
        type: "POST",
        url: "/funcoes",
        data: formData,
        dataType: "json",
        processData: false,
		contentType: false,
        success: function( data )
        {
            if(data.result == true){
                Swal.fire({

                    icon: 'success',
                    title: data.msg,
                    showConfirmButton: true,
                    timer: 5000

                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.location.href = data.url;
                    }
                })
            }



        }
    });
    return false;
});

//Modulo Campanhas

$("#addProdutoCampanhas").on("click",function(e) {

    var produto = $("#selectProduto option:selected").val();
    var nome = $("#selectProduto option:selected").attr('data-nome');
    var tipo = $("#selectProduto option:selected").attr("data-tipo");
    var sku = $("#selectProduto option:selected").attr("data-sku");
    var qtd = $("input[name=qtd]").val();

    if(produto === ""){
        Swal.fire(
            'Atenção!',
            'Um produto precisa ser selecionado!',
            'error'
        );
        return false;
    }
    if(qtd === ""){
        Swal.fire(
            'Atenção!',
            'Uma quantidade precisa ser inserida!',
            'error'
        );
        return false;
    }
    $.ajax({
        type: "POST",
        url: "/funcoes",
        data: {funcao: "addProdutoCampanha", id: produto, nome: nome, qtd:qtd, tipo: tipo, sku: sku},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {

            if(data.result == true){

                $('#exibe').load('/campanhas/loadProdutos');

            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });
});

$('select#tipoSolicitacao').on('change', function() {

    var sla = $("select#tipoSolicitacao option:selected").attr('data-sla');

    $('input[name=sla]').val(sla);
    $('input[name=slaMostra]').val(sla);
});
$('select#transacao').on('change', function() {

    var codigo = $("select#transacao option:selected").attr('data-codigo');

    $('input[name=codigo_transacao]').val(codigo);

});

$('#modalEndereco').on('show.bs.modal', function (e) {

    var pedido = $(e.relatedTarget).attr('data-id');

    $.ajax({
        type: "POST",
        url: "/transacoes/funcoes",
        data: { tipo: "getEndereco", id: pedido},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {
            if(data.result == true){
                //$('#exibe').load('/produtos/loadKitProduto');
                $('#exibe').html(data.html);
            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });

});
//Rastreamento
$('#modalRastreamento').on('show.bs.modal', function (e) {

    var pedido = $(e.relatedTarget).attr('data-id');

    $.ajax({
        type: "POST",
        url: "/transacoes/funcoes",
        data: { tipo: "getRastreamento", id: pedido},
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function( data )
        {
            if(data.result == true){
                //$('#exibe').load('/produtos/loadKitProduto');
                $('#exibeRastreamento').html(data.html);
            }

        },
        error: function(data) { // executa quando não responde ou envia um erro
        // em caso de erro você pode dar um alert('msg erro');
            //alert('Desculpe, houve um erro interno.');
            //alert(data);
        }
    });

});
//Tooltip
$('[data-toggle="tooltip"]').tooltip();

$(document).ready(function(){
    $('#checkbox').on('change', function(){
        $('#password').attr('type',$('#checkbox').prop('checked')==true?"text":"password");
    });
});
/*$(document).ready(function() {
    $("#telefone").inputmask({
        mask: ["(99) 9999-9999", "(99) 99999-9999", ],
        keepStatic: true
    });
});*/

//Modulo Clientes

// let foto = document.getElementById('foto');
// let uploadImg = document.getElementById('uploadImg');

// foto.addEventListener('click', () => {
//     file.click();
// });

// file.addEventListener('change', (event) => {
//     let reader = new FileReader();

//     reader.onload = () => {
//         foto.src = reader.result;
//     }

//     reader.readAsDataURL(file.files[0]);
// })
