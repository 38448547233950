/**
 * Panel Scripts & Plugins
 */
require('./common');
require('jquery-ui-dist/jquery-ui');
require('./components/ControlSidebar');
require('./components/_ionRangeSlider');
require('./components/_select2');
require('./components/_datetimepicker');
require('./components/_trumbowyg');
require('./components/_bootstrap-tagsinput');
//require('./components/_fullcalendar');
require('./components/_responsiveTabs');
require('./components/_todo');
//require('./components/_dropzone');
require('./components/morrisCharts');
require('./components/sparkLines');
require('./components/echarts');
require('./components/easyPieChart');
require('./components/_JQVmap');
require('./components/utils');
require('./components/_colorpicker');
require('./components/_toastr');
require('./components/_stepper');
require('./components/_datatables');
require('./components/floatCharts');
require('./components/_validations');
require('./components/_inputmask');
